<template>
  <div class="not-found-page d-flex align-items-center">
    <img class="logo"
         src="../assets/images/login-logo.png"
         alt="Premat">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title text-center">Désolé,<br> la page demandée n'existe pas...</h3>
        <p class="card-text">
          <router-link :to="{ name: 'authorizations.index' }"
                       class="btn btn-block btn-secondary">
            Retours au tableau de bord
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>

<style lang="scss" scoped>
  .not-found-page {
    min-height: 100vh;
    background: url('../assets/images/login-background.jpg') no-repeat center center fixed;
    background-size: cover;
    position: relative;
  }

  .logo {
    position: absolute;
    top: 60px;
    right: 90px;
    width: 306px;
  }

  .card {
    margin-left: 200px;
  }

  .card-body {
    padding: 60px 45px 70px 45px;
  }

  .card-title {
    padding: 0 20px;
  }
</style>
